import {
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useLazyQuery,
  LazyQueryHookOptions
} from '@apollo/react-hooks';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerPreparationProgress,
  reducerUpdateCountryByProject,
  reducerUpdateIndustries
} from 'src/features/onboarding';
import { reducerUpdateLanguageSelected } from 'src/features/onlineMediaStream';
import { useAlert } from 'src/hooks/useAlert';
import { ICountry, IIndustry, IPreparation } from 'src/models/onboarding';
import { STREAM_PANEL_SOCIAL_MEDIA } from 'src/route';
import {
  API_ONBOARDING_CREATE_ACCOUNT_OR_KEYWORD,
  API_ONBOARDING_CREATE_CLIPPING,
  API_ONBOARDING_CREATE_ENTITY,
  API_ONBOARDING_UPDATE_PROJECT_NAME,
  API_ONBOARDING_UPDATE_ROLE_AND_INDUSTRY,
  API_ONBOARDING_UPDATE_STATUS_ONBOARDING
} from './graphql/onboarding/mutation';
import {
  ONBOARDING_GET_COUNTRY,
  ONBOARDING_GET_ENTITY_BY_NAME,
  ONBOARDING_GET_INDUSTRY,
  ONBOARDING_GET_PREPARATION
} from './graphql/onboarding/query';
import { IClipping } from 'src/models/onlineMedia';
import { TStreamType } from 'src/models/objectManagement';
import { TSocialMedia } from 'src/models/general';

interface IGetIndustriesResponse {
  onBoarding_getIndustry: Array<IIndustry>;
}

interface IGetPreparationProgressResponse {
  onBoarding_getDataPreparationProgress: IPreparation;
}

interface IGetCountryResponse {
  onBoarding_getCountryByProjectId: ICountry;
}

export interface IClippingInput {
  name: string;
  phrases: Array<Array<string>>;
  startingDate: Date;
  excludePhrases?: Array<string>;
}

export interface ITempClippingInput extends IClippingInput {
  id: string;
}

export interface ICreateClippingsPayload {
  projectId: string;
  clippingInputs: IClippingInput[];
}

interface ICreateClippingsResponse {
  onBoarding_createMultipleClippingV5: {
    clippingList: IClipping[];
  };
}

export interface IGetEntityByNamePayload {
  name: string;
}

export interface IEntityOutput {
  entityId: string;
  entityName: string;
  category: string;
  categoryParent: string;
  displayPicture: string | null;
}

interface IGetEntityByNameResponse {
  indsight_searchEntityByName: IEntityOutput[];
}

interface ICreateEntityPayload {
  projectId: string;
  entityIdList: string[];
}

interface IEntityObject {
  id: string;
  objectName: string;
  streamType: TStreamType;
  socialMedia: TSocialMedia;
}

interface ICreateEntityResponse {
  indsight_addEntityToProject: IEntityObject[];
}

const useOnboarding = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const { handleClickAlert } = useAlert();

  const fetchListIndustry = async function () {
    return await client
      .query<IGetIndustriesResponse>({
        query: ONBOARDING_GET_INDUSTRY
      })
      .then((response) => {
        return { ...response, data: response.data.onBoarding_getIndustry };
      });
  };

  const getListIndustry = async function () {
    const response = await fetchListIndustry();
    dispatch(reducerUpdateIndustries(response));
  };

  const fetchPreparationProgress = async function (projectId: string) {
    return await client
      .query<IGetPreparationProgressResponse>({
        query: ONBOARDING_GET_PREPARATION,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onBoarding_getDataPreparationProgress
        };
      });
  };

  const getPreparationProgress = async function (projectId: string) {
    const response = await fetchPreparationProgress(projectId);
    try {
      if (response.data.status === 'completed') {
        window.location.assign(STREAM_PANEL_SOCIAL_MEDIA);
      }
    } catch (error) {
      return;
    }
    dispatch(reducerPreparationProgress(response));
  };

  const fetchCountry = async function (projectId: string) {
    return await client
      .query<IGetCountryResponse>({
        query: ONBOARDING_GET_COUNTRY,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onBoarding_getCountryByProjectId
        };
      });
  };

  const getOnBoardingCountry = async function (payload: string) {
    const response = await fetchCountry(payload);
    dispatch(reducerUpdateCountryByProject(response));
    // if (response.data?.name === 'Indonesia') {
    //   dispatch(reducerUpdateLanguageSelected('id'));
    // } else {
    //   dispatch(reducerUpdateLanguageSelected('en'));
    // }
  };

  const useGetEntityByName = (
    // variables: IGetEntityByNamePayload,
    options?: LazyQueryHookOptions<
      IGetEntityByNameResponse,
      IGetEntityByNamePayload
    >
  ) => {
    return useLazyQuery<IGetEntityByNameResponse, IGetEntityByNamePayload>(
      ONBOARDING_GET_ENTITY_BY_NAME,
      { ...options }
    );
  };

  const [updateRoleAndIndustry, responseUpdateRoleAndIndustry] = useMutation(
    API_ONBOARDING_UPDATE_ROLE_AND_INDUSTRY
  );
  const [updateProjectName, responseUpdateProjectName] = useMutation(
    API_ONBOARDING_UPDATE_PROJECT_NAME
  );
  const [createObjects, responseCreateObjects] = useMutation(
    API_ONBOARDING_CREATE_ACCOUNT_OR_KEYWORD,
    {
      onError: (err) =>
        handleClickAlert({
          message: 'Object quota full, please upgrade your plan to track more.',
          horizontal: 'center',
          vertical: 'top',
          severity: 'error'
        })
    }
  );
  const [updateStatusOnboarding, responseStatusOnboarding] = useMutation(
    API_ONBOARDING_UPDATE_STATUS_ONBOARDING
  );

  const [createClippings, resCreateClippings] = useMutation<
    ICreateClippingsResponse,
    ICreateClippingsPayload
  >(API_ONBOARDING_CREATE_CLIPPING, {
    onError: () =>
      handleClickAlert({
        message: 'Clipping quota full.',
        horizontal: 'center',
        vertical: 'top',
        severity: 'error'
      })
  });

  const [createEntity, resCreateEntity] = useMutation<
    ICreateEntityResponse,
    ICreateEntityPayload
  >(API_ONBOARDING_CREATE_ENTITY, {
    onError: () =>
      handleClickAlert({
        message: 'Object quota full, please upgrade your plan to track more.',
        horizontal: 'center',
        vertical: 'top',
        severity: 'error'
      })
  });

  return {
    updateRoleAndIndustry,
    responseUpdateRoleAndIndustry,
    updateProjectName,
    responseUpdateProjectName,
    getListIndustry,
    createObjects,
    responseCreateObjects,
    updateStatusOnboarding,
    responseStatusOnboarding,
    getPreparationProgress,
    getOnBoardingCountry,
    createClippings,
    resCreateClippings,
    useGetEntityByName,
    createEntity,
    resCreateEntity
  };
};

export default useOnboarding;
