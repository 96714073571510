import { ReactNode } from 'react';

import {
  ONLINE_MEDIA,
  ONLINE_MEDIA_CLIPPING,
  SOCIAL_MEDIA,
  SOCIAL_MEDIA_CAMPAIGN,
  SOCIAL_MEDIA_COMMUNITY,
  SOCIAL_MEDIA_CONTENT,
  SOCIAL_MEDIA_CONVERSATION,
  SOCIAL_MEDIA_OVERVIEW,
  SOCIAL_MEDIA_COMPARISON,
  STREAM_PANEL,
  STREAM_PANEL_ONLINE_MEDIA,
  STREAM_PANEL_SOCIAL_MEDIA,
  SETTINGS,
  ONLINE_MEDIA_STATISTIC,
  CUSTOM_DASHBOARD,
  ONLINE_MEDIA_COMPARISON,
  SOCIAL_MEDIA_SNA,
  SOCIAL_MEDIA_DNA,
  PEOPLE_PANEL,
  STREAM_PANEL_OFFLINE_MEDIA,
  OFFLINE_MEDIA,
  OFFLINE_MEDIA_STATISTIC,
  CUSTOMIZATION,
  CUSTOM_REPORT,
  SOCIAL_MEDIA_CLUSTERING,
  OFFLINE_MEDIA_CLIPPING,
  OFFLINE_MEDIA_COMPARISON,
  CHAT_NOW,
  CHAT_NOW_NEW_CHAT,
  CHAT_NOW_CHAT_HISTORY
} from 'src/route';
import GridViewIcon from '@mui/icons-material/GridView';
//import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import MessageIcon from '@mui/icons-material/Message';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedIcon from '@mui/icons-material/Feed';
import ForumIcon from '@mui/icons-material/Forum';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'MAIN',
    items: [
      {
        name: 'Customization',
        link: CUSTOMIZATION,
        icon: GridViewIcon,
        items: [
          {
            name: 'Dashboard',
            link: CUSTOM_DASHBOARD
          },
          { name: 'Report', link: CUSTOM_REPORT }
        ]
      },
      {
        name: 'Stream Panel',
        link: STREAM_PANEL,
        icon: ViewStreamIcon,
        items: [
          {
            name: 'Social Media',
            link: STREAM_PANEL_SOCIAL_MEDIA
          },
          {
            name: 'Online Media',
            link: STREAM_PANEL_ONLINE_MEDIA
          },
          {
            name: 'People Panel',
            link: PEOPLE_PANEL
          },
          {
            name: 'Others Media',
            link: STREAM_PANEL_OFFLINE_MEDIA
          }
        ]
      },
      {
        name: 'Chat Now',
        link: CHAT_NOW,
        icon: ForumIcon,
        items: [
          { name: 'New Chat', link: CHAT_NOW_NEW_CHAT }
          // { name: 'Chat History', link: CHAT_NOW_CHAT_HISTORY }
        ]
      }
    ]
  },
  {
    heading: 'ANALYTIC',
    items: [
      {
        name: 'Social Media',
        link: SOCIAL_MEDIA,
        icon: MessageIcon,
        items: [
          {
            name: 'Overview',
            link: SOCIAL_MEDIA_OVERVIEW
          },
          {
            name: 'Content',
            link: SOCIAL_MEDIA_CONTENT
          },
          {
            name: 'Community',
            link: SOCIAL_MEDIA_COMMUNITY
          },
          {
            name: 'Campaign',
            link: SOCIAL_MEDIA_CAMPAIGN
          },
          {
            name: 'Conversation',
            link: SOCIAL_MEDIA_CONVERSATION
          },
          {
            name: 'Comparison',
            link: SOCIAL_MEDIA_COMPARISON
          },
          {
            name: 'SNA',
            link: SOCIAL_MEDIA_SNA
          },
          {
            name: 'DNA',
            link: SOCIAL_MEDIA_DNA
          },
          {
            name: 'Clustering',
            link: SOCIAL_MEDIA_CLUSTERING
          }
        ]
      },
      {
        name: 'Online Media',
        link: ONLINE_MEDIA,
        icon: ArticleIcon,
        items: [
          {
            name: 'Statistic',
            link: ONLINE_MEDIA_STATISTIC
          },
          {
            name: 'Clipping',
            link: ONLINE_MEDIA_CLIPPING
          },
          {
            name: 'Comparison',
            link: ONLINE_MEDIA_COMPARISON
          }
        ]
      },
      {
        name: 'Others Media',
        link: OFFLINE_MEDIA,
        icon: FeedIcon,
        items: [
          {
            name: 'Statistic',
            link: OFFLINE_MEDIA_STATISTIC
          },
          {
            name: 'Clipping',
            link: OFFLINE_MEDIA_CLIPPING
          },
          {
            name: 'Comparison',
            link: OFFLINE_MEDIA_COMPARISON
          }
        ]
      }
    ]
  },
  {
    heading: 'PREFERENCES',
    items: [
      {
        name: 'Settings',
        link: SETTINGS,
        icon: SettingsIcon
      }
    ]
  }
];

export default menuItems;
