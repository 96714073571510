export const REGISTER_APP_SUMO = '/register_app_sumo';
export const REGISTER = '/register';
export const REGISTER_ACCOUNT = `${REGISTER}/account`;
export const REGISTER_CHECKOUT = `${REGISTER}/checkout`;
export const REGISTER_SUCCESS = `${REGISTER}/success`;
export const RESET_PASSWORD = `/password-recovery`;
export const USERS = `/users`;
export const USERS_SIGN_IN = `${USERS}/sign_in`;
export const USERS_FORGOT_PASSWORD = `${USERS}/forgot_password`;
export const ONBOARDING = '/onboarding';
export const ONBOARDING_ADD_PROFILE = `${ONBOARDING}/add_profile`;
export const ONBOARDING_CREATE_PROJECT = `${ONBOARDING}/create_project`;
export const ONBOARDING_SETUP_PROGRESS = `${ONBOARDING}/setup_progress`;
export const ONBOARDING_CREATE_SOCIAL_PROFILE = `${ONBOARDING}/social_profile`;
export const ONBOARDING_CREATE_KEYWORD_OR_HASHTAG = `${ONBOARDING}/keyword_or_hashtag`;
export const ONBOARDING_CREATE_CLIPPING = `${ONBOARDING}/clipping`;
export const ONBOARDING_CREATE_ENTITY = `${ONBOARDING}/entity`;
export const ONBOARDING_POPULATING_DATA = `${ONBOARDING}/populating_data`;
export const STATUS = '/status';
export const STATUS_LOADING = `${STATUS}/loading`;
export const SOCIAL_MEDIA = '/social_media';
export const SOCIAL_MEDIA_OVERVIEW = `${SOCIAL_MEDIA}/overview`;
export const SOCIAL_MEDIA_CONTENT = `${SOCIAL_MEDIA}/content`;
export const SOCIAL_MEDIA_CONVERSATION = `${SOCIAL_MEDIA}/conversation`;
export const SOCIAL_MEDIA_CAMPAIGN = `${SOCIAL_MEDIA}/campaign`;
export const SOCIAL_MEDIA_COMMUNITY = `${SOCIAL_MEDIA}/community`;
export const SOCIAL_MEDIA_COMPARISON = `${SOCIAL_MEDIA}/comparison`;
export const SOCIAL_MEDIA_NETWORK_ANALYSIS = `${SOCIAL_MEDIA}/network_analysis`;
export const SOCIAL_MEDIA_SNA = `${SOCIAL_MEDIA}/sna`;
export const SOCIAL_MEDIA_DNA = `${SOCIAL_MEDIA}/dna`;
export const SOCIAL_MEDIA_CLUSTERING = `${SOCIAL_MEDIA}/clustering`;
export const SOCIAL_MEDIA_ADD_COMPARISON = `${SOCIAL_MEDIA}/comparison/form-add`;
export const STREAM_PANEL = '/stream_panel';
export const STREAM_PANEL_SOCIAL_MEDIA = `${STREAM_PANEL}/social_media`;
export const STREAM_PANEL_ONLINE_MEDIA = `${STREAM_PANEL}/online_media`;
export const STREAM_PANEL_OFFLINE_MEDIA = `${STREAM_PANEL}/offline_media`;
export const ONLINE_MEDIA = '/online_media';
export const ONLINE_MEDIA_CLIPPING = `${ONLINE_MEDIA}/clipping`;
export const ONLINE_MEDIA_STATISTIC = `${ONLINE_MEDIA}/statistic`;
export const ONLINE_MEDIA_COMPARISON = `${ONLINE_MEDIA}/comparison`;
export const OFFLINE_MEDIA = '/offline_media';
export const OFFLINE_MEDIA_STATISTIC = `${OFFLINE_MEDIA}/statistic`;
export const OFFLINE_MEDIA_CLIPPING = `${OFFLINE_MEDIA}/clipping`;
export const OFFLINE_MEDIA_COMPARISON = `${OFFLINE_MEDIA}/comparison`;
export const SETTINGS = '/settings';
export const SETTINGS_OBJECT = `${SETTINGS}/objects`;
export const SETTINGS_GROUP = `${SETTINGS}/group`;
export const SETTINGS_LABEL = `${SETTINGS}/label`;
export const SETTINGS_ENTITY = `${SETTINGS}/entity`;
export const SETTINGS_CUSTOM_DASHBOARD = `${SETTINGS}/custom_dashboard`;
export const SETTINGS_AUTO_LABEL = `${SETTINGS}/auto_label`;
export const SETTING_ALERT_SYSTEM = `${SETTINGS}/alert-system`;
export const SETTINGS_STOP_WORDS = `${SETTINGS}/stop-words`;
export const CUSTOMIZATION = '/customization';
export const CUSTOM_DASHBOARD = `${CUSTOMIZATION}/custom_dashboard`;
export const CUSTOM_REPORT = `${CUSTOMIZATION}/custom_report`;
export const ADD_AUTO_LABEL = `${SETTINGS}/auto-label/form`;
export const ADD_AUTO_LABEL_ONM = `${SETTINGS}/auto-label-onm/form`;
export const LOADING_PROCESS = '/loading-process';
export const REGISTER_INVITATION = '/register-invitation';
export const WARNING = `/warning`;
export const WARNING_OBJECT_OVERLAP = `${WARNING}/object_overlap`;
export const BILLING = `/billing`;
export const BILLING_STREAM_QUOTA = `/${BILLING}/stream_plan`;
export const BILLING_UPDATE_PLAN = `/${BILLING}/update_plan`;
export const BILLING_STREAM_CHECKOUT = `/${BILLING}/stream_checkout`;
export const BILLING_CHOOSE_YOUR_PLAN = `/${BILLING}/choose_your_plan`;
export const BILLING_PLAN_CHECKOUT = `/${BILLING}/plan_checkout`;
export const BILLING_MANAGE_OBJECT_OVERLAP = `/${BILLING}/manage_objects`;
export const UPLOAD_FILE = `/static/media/uploaded-images/`;
export const PEOPLE_PANEL = `${STREAM_PANEL}/people_panel`;
export const CHAT_NOW = '/chat_now';
export const CHAT_NOW_NEW_CHAT = `${CHAT_NOW}/new_chat`;
export const CHAT_NOW_CHAT_HISTORY = `${CHAT_NOW}/chat_history`;
export const CHAT_NOW_GUIDELINES = `${CHAT_NOW}/guidelines`;
