import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChatState {
  conversationId: string | null;
}

const initialState: ChatState = {
  conversationId: null
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConversationId: (state: ChatState, action: PayloadAction<string>) => {
      state.conversationId = action.payload;
    },
    clearConversationId: (state: ChatState) => {
      state.conversationId = null;
    }
  }
});

export const { setConversationId, clearConversationId } = chatSlice.actions;

export default chatSlice.reducer;
