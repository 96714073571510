import gql from 'graphql-tag';
import {
  analyticArgumentsOfflineMedia,
  analyticArgumentsQueryOfflineMedia
} from '../analyticArgument';

const numericOutput = `
  name
  value
`;

const chronologicalOutput = `
  contentType
  values {
    timestamp
    value
  }
`;

export const GET_TOTAL_ARTICLE_BY_OFFLINE_MEDIA = gql`
  query offlineMedia_getTotalArticleByOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTotalArticleByOfflineMedia(${analyticArgumentsOfflineMedia}) {
      media
      total
      sentimentPositive
      sentimentNeutral
      sentimentNegative
    }
  }
`;

export const GET_WORD_CLOUD_OFFLINE_NEWS = gql`
  query offlineMedia_getWordCloudOfflineNews(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getWordCloudOfflineNews(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_SENTIMENT_ANALYSIS_OFFLINE_MEDIA = gql`
  query offlineMedia_getSentimentAnalysisOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getSentimentAnalysisOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOP_PEOPLE_OFFLINE_MEDIA = gql`
  query offlineMedia_getTopPeopleOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTopPeopleOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOP_LOCATION_OFFLINE_MEDIA = gql`
  query offlineMedia_getTopLocationOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTopLocationOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_OFFLINE_ARTICLE = gql`
  query offlineMedia_getTotalOfflineArticle(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTotalOfflineArticle(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_OFFLINE_MEDIA = gql`
  query offlineMedia_getTotalOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTotalOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_TOTAL_PR_VALUES_OFFLINE_MEDIA = gql`
  query offlineMedia_getTotalPRValuesOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getTotalPRValuesOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${numericOutput}
    }
  }
`;

export const GET_ARTICLE_OFFLINE_MEDIA = gql`
  query offlineMedia_getArticleOfflineMedia(${analyticArgumentsQueryOfflineMedia}, $page: Int!, $limit: Int!) {
    offlineMedia_getArticleOfflineMedia(${analyticArgumentsOfflineMedia}, page: $page, limit: $limit) {
      limit
      page
      totalRows
		totalPages
		result {
      originalId
      createdAt
      datePublished
      title
      content
      people
      place
      sentiment
      sourceName
      updatedAt
      writer
      prValues
      isEdited
      keywordList
      clippingList {
          id
          name
          isActive
          createdAt
      }
      labelArticleList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
      }
      listOfClippingId
      attachments {
          link
      }
    }
    }
  }
`;

export const GET_CHRONOLOGICAL_OFFLINE_ARTICLE = gql`
  query offlineMedia_getChronologicalOfflineArticle(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getChronologicalOfflineArticle(${analyticArgumentsOfflineMedia}) {
      ${chronologicalOutput}
    }
  }
`;

export const GET_CHRONOLOGICAL_OFFLINE_MEDIA = gql`
  query offlineMedia_getChronologicalOfflineMedia(${analyticArgumentsQueryOfflineMedia}) {
    offlineMedia_getChronologicalOfflineMedia(${analyticArgumentsOfflineMedia}) {
      ${chronologicalOutput}
    }
  }
`;

export const GET_CHRONOLOGICAL_SENTIMENT_OFFLINE_MEDIA = gql`
  query offlineMedia_getChronologicalSentimentOfflineMedia(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $mediaList: [String!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $sentiment: [Sentiment!]
    $labelList: [Id!]
    $clippingId: Id
    $includeKeywords: [String!]
    $excludeKeywords: [String!]
    $phrases: [String!]
    $languageList: [String!]
    $searchType: SearchType
    $validationList: [ValidationType!]
  ) {
    offlineMedia_getChronologicalSentimentOfflineMedia(
      projectId: $projectId
      start: $start
      end: $end
      mediaList: $mediaList
      granularity: $granularity
      timezone: $timezone
      sentiment: $sentiment
      labelList: $labelList
      clippingId: $clippingId
      includeKeywords: $includeKeywords
      excludeKeywords: $excludeKeywords
      phrases: $phrases
      languageList: $languageList
      searchType: $searchType
      validationList: $validationList
    ) {
      contentType
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_LABEL = gql`
  query article_getLabelArticle(
    $projectId: Id!
    $page: Int!
    $limit: Int!
    $name: String!
    $categoryArticle: CategoryArticle!
  ) {
    article_getLabelArticle(
      projectId: $projectId
      page: $page
      limit: $limit
      name: $name
      categoryArticle: $categoryArticle
    ) {
      result {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      totalPages
      totalRows
      page
      limit
    }
  }
`;

export const GET_LABEL_BY_ID = gql`
  query article_getLabelArticleById($labelId: Id!) {
    article_getLabelArticleById(labelId: $labelId) {
      id
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const GET_AUTO_LABEL_ARTICLE = gql`
  query article_getAutoLabel(
    $projectId: Id!
    $limit: Int!
    $page: Int!
    $keyword: String
  ) {
    article_getAutoLabel(
      projectId: $projectId
      limit: $limit
      page: $page
      keyword: $keyword
    ) {
      page
      limit
      totalRows
      totalPages
      result {
        clippingList {
          id
          name
          createdAt
          startDate
        }
        createdAt
        description
        id
        isActive
        labelList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        name
        phrasesList
        projectId
        start
      }
    }
  }
`;

export const GET_CLIPPING_LIST = gql`
  query article_getClippingArticle(
    $projectId: Id!
    $page: Int!
    $limit: Int!
    $name: String!
    $categoryArticle: CategoryArticle!
  ) {
    article_getClippingArticle(
      projectId: $projectId
      page: $page
      limit: $limit
      name: $name
      categoryArticle: $categoryArticle
    ) {
      result {
        id
        name
        createdAt
        startingDate
        phrases
      }
      totalPages
      totalRows
      page
      limit
    }
  }
`;

export const GET_CLIPPING_BY_ID = gql`
  query article_getClippingArticleById($clippingId: Id!) {
    article_getClippingArticleById(clippingId: $clippingId) {
      id
      name
      createdAt
      startingDate
      phrases
    }
  }
`;

export const GET_COMPARISON = gql`
  query comparisonOfflineMedia_getComparisonNamespaceList($projectId: Id!) {
    comparisonOfflineMedia_getComparisonNamespaceList(projectId: $projectId) {
      id
      namespace
      displayName
      isDefault
      createdAt
      colors
      sourceTypeOnm
      __typename
    }
  }
`;

export const GET_COMPARISON_BY_ID = gql`
  query comparisonOfflineMedia_getComparisonById($comparisonId: Id!) {
    comparisonOfflineMedia_getComparisonById(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      createdAt
      colors
      groups {
        id
        name
        displayName
        createdAt
        clippingList
        clippingListObject {
          id
          name
          createdAt
        }
        labelList
        labelListObject {
          id
          name
          createdAt
        }
      }
      __typename
    }
  }
`;

export const GET_BRAND_POPULARITY = gql`
  query comparisonOfflineMedia_getBrandPopularity(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOfflineMedia_getBrandPopularity(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      name
      value
      __typename
    }
  }
`;

export const GET_CHRONOLOGICAL_MEDIA = gql`
  query comparisonOfflineMedia_getChronologicalMediaByBrand(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOfflineMedia_getChronologicalMediaByBrand(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      name
      values {
        timestamp
        value
      }
      __typename
    }
  }
`;

export const GET_BRAND_POSITIONING = gql`
  query comparisonOfflineMedia_getBrandPositioning(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOfflineMedia_getBrandPositioning(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      name
      popularity
      positivity
      __typename
    }
  }
`;

export const GET_BRAND_SENTIMENT = gql`
  query comparisonOfflineMedia_getBrandSentiment(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOfflineMedia_getBrandSentiment(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      groupName
      positive
      negative
      neutral
      __typename
    }
  }
`;

export const GET_CHRONOLOGICAL_ARTICLE = gql`
  query comparisonOfflineMedia_getChronologicalArticleByBrand(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
    $validationList: [StreamValidation!]
  ) {
    comparisonOfflineMedia_getChronologicalArticleByBrand(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
      validationList: $validationList
    ) {
      name
      values {
        timestamp
        value
      }
      __typename
    }
  }
`;

export const GET_ADVANCE_METRIC = gql`
  query comparisonOfflineMedia_getAdvanceMetricOfflineMedia(
    $projectId: Id!
    $comparisonId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $granularity: GranularityInput!
    $timezone: Int!
    $phrase: String
    $sentiment: [Sentiment!]
    $postOwnership: [PostOwnership!]
    $labelList: [Id!]
  ) {
    comparisonOfflineMedia_getAdvanceMetricOfflineMedia(
      projectId: $projectId
      comparisonId: $comparisonId
      start: $start
      end: $end
      granularity: $granularity
      timezone: $timezone
      phrase: $phrase
      sentiment: $sentiment
      postOwnership: $postOwnership
      labelList: $labelList
    ) {
      category
      totalArticle
      totalMedia
      sentimentPositive
      sentimentNegative
      sentimentNeutral
      prValue
    }
  }
`;

export const GET_ALL_MEDIA_OFFLINE = gql`
  query OfflineMedia_getAllOfflineMedia {
    offlineMedia_getAllOfflineMedia {
      media
    }
  }
`;
