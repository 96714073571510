import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation Chat_chatPost(
    $conversationId: String!
    $content: String!
    $projectId: Id!
  ) {
    chat_chatPost(
      conversationId: $conversationId
      content: $content
      projectId: $projectId
    ) {
      id
      answer
      question
      createdAt
    }
  }
`;
