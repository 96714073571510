import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery
} from '@apollo/client';
import { useAppDispatch } from 'src/app/hooks';
import { setConversationId } from 'src/features/chatNow';
import { SEND_MESSAGE } from './graphql/chat/mutation';
import { GET_CONVERSATION_ID } from './graphql/chat/query';

export interface GetConversationIdResponse {
  chat_getConversationId: {
    conversationId: string;
  };
}

export interface SendMessageParams {
  conversationId: string;
  content: string;
  projectId: string;
}

export interface Conversation {
  id: string;
  answer: string;
  question: string;
  createdAt: string;
}

export interface SendMessageResponse {
  chat_chatPost: Conversation;
}

const useChat = () => {
  const dispatch = useAppDispatch();

  const useGetConversationId = (
    option?: QueryHookOptions<GetConversationIdResponse>
  ) => {
    return useQuery<GetConversationIdResponse>(GET_CONVERSATION_ID, {
      onCompleted: (data) => {
        dispatch(setConversationId(data.chat_getConversationId.conversationId));
      },
      fetchPolicy: 'no-cache',
      ...option
    });
  };

  const useSendMessage = (
    options?: MutationHookOptions<SendMessageResponse, SendMessageParams>
  ) => {
    return useMutation<SendMessageResponse, SendMessageParams>(
      SEND_MESSAGE,
      options ?? {}
    );
  };

  return {
    useGetConversationId,
    useSendMessage
  };
};

export default useChat;
