import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router';
import { CHAT_NOW_CHAT_HISTORY, CHAT_NOW_GUIDELINES } from 'src/route';
import useChat from 'src/services/useChat';
import { useAppDispatch } from 'src/app/hooks';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
      color: ${theme.header.textColor};
      padding: ${theme.spacing(1, 4, 0, 4)};
      right: 0;
      z-index: 5;
      box-shadow: ${theme.header.boxShadow};
      justify-content: space-between;
      width: 100%;
      background: ${theme.header.background};
      @media (min-width: ${theme.breakpoints.values.lg}px) {
          left: ${theme.sidebar.width};
          width: auto;
      }
  `
);

const HeaderChatNowNewChat = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useGetConversationId } = useChat();
  const { refetch: refetchConversationId } = useGetConversationId();

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h1"
              sx={{
                mr: 1
              }}
            >
              Chat Now
            </Typography>
            <Stack gap={1} direction="row">
              <Button
                variant="contained"
                sx={{ bgcolor: theme.colors.primary.dark, borderRadius: '6px' }}
                endIcon={<CreateIcon />}
                onClick={() => refetchConversationId()}
              >
                New Chat
              </Button>
              {/* <Button
                variant="contained"
                sx={{ borderRadius: '6px' }}
                onClick={() => navigate(CHAT_NOW_CHAT_HISTORY)}
              >
                <HistoryIcon />
              </Button> */}
              <Button
                variant="outlined"
                sx={{ borderRadius: '6px' }}
                onClick={() => navigate(CHAT_NOW_GUIDELINES)}
              >
                <DescriptionIcon />
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default HeaderChatNowNewChat;
