import {
  BorderStyle,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  ITableBordersOptions,
  ITableWidthProperties,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
  ImageRun
} from 'docx';
import { IOfflineMedia } from 'src/models/offlineMedia';
import moment from 'moment';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64.split(',')[1]);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

async function convertPdfToImage(
  pdfUrl: string
): Promise<{ data: string; width: number; height: number }[]> {
  try {
    // Fetch the PDF file
    const response = await fetch(pdfUrl);
    const arrayBuffer = await response.arrayBuffer();

    // Load the PDF document
    const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
    const pdf = await loadingTask.promise;

    const images: Array<{ data: string; width: number; height: number }> = [];

    // Convert each page to an image
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });

      // Create a canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page to canvas
      await page.render({
        canvasContext: context,
        viewport: viewport
      }).promise;

      // Convert canvas to base64 image
      images.push({
        data: canvas.toDataURL('image/jpeg', 0.8),
        width: viewport.width,
        height: viewport.height
      });
    }

    return images;
  } catch (error) {
    console.error('Error converting PDF to image:', error);
    return [];
  }
}

async function getFileType(url: string): Promise<string> {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentType = response.headers.get('content-type');
    return contentType || '';
  } catch (error) {
    console.error('Error getting file type:', error);
    // Fallback to checking file extension
    const extension = url.split('.').pop()?.toLowerCase() || '';
    return extension === 'pdf' ? 'application/pdf' : '';
  }
}

export async function generateOfmDoc(streams: IOfflineMedia[]) {
  const generateTableDetail = (stream: IOfflineMedia) => {
    const {
      title,
      sourceName,
      writer,
      datePublished,
      prValues,
      sentiment,
      people
    } = stream;

    const borderOptions: ITableBordersOptions = {
      top: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      bottom: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      left: { style: BorderStyle.NIL },
      right: { style: BorderStyle.NIL }
    };

    const generateTopPeoples = (people: string[]) => people.join(', ');

    const createTableCell = (
      text: string,
      borderOptions: ITableBordersOptions,
      width: ITableWidthProperties
    ) => {
      return new TableCell({
        borders: borderOptions,
        width: width,
        children: [new Paragraph(text)]
      });
    };

    return new Table({
      borders: {
        insideHorizontal: { style: BorderStyle.NIL },
        insideVertical: { style: BorderStyle.NIL }
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      rows: [
        new TableRow({
          children: [
            createTableCell('Judul', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(title, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Nama Media', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sourceName, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Penulis', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(writer[0], borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Tanggal', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(
              moment(datePublished).format('llll'),
              borderOptions,
              {
                size: 72,
                type: WidthType.PERCENTAGE
              }
            )
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Sentiment', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sentiment, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Top People', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(generateTopPeoples(people), borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Ad Value', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(`Rp ${prValues.toLocaleString()}`, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        })
      ]
    });
  };

  const generateParagraphs = (content: string) => {
    const paragraphStrings = content.split('\n');
    const paragraphs = paragraphStrings.map((text) => {
      return new Paragraph(text);
    });
    return paragraphs;
  };

  // const generateAttachmentLink = (attachments: string[]) => {
  //   if (attachments.length <= 0) return new Paragraph('');
  //   return new Paragraph({
  //     children: [
  //       new TextRun({
  //         text: '',
  //         break: 1
  //       }),
  //       new ExternalHyperlink({
  //         children: [
  //           new TextRun({
  //             text: attachments[0],
  //             style: 'Hyperlink'
  //           })
  //         ],
  //         link: attachments[0]
  //       })
  //     ]
  //   });
  // };

  const generateAttachmentContent = async (attachments: string[]) => {
    if (attachments.length <= 0) return [new Paragraph('')];

    const content: Paragraph[] = [];
    const MAX_WIDTH = 300; // Maximum width in document

    for (const attachment of attachments) {
      try {
        // Get file type first
        const fileType = await getFileType(attachment);

        if (fileType.includes('pdf')) {
          // Convert PDF to images
          const images = await convertPdfToImage(attachment);

          // Add each image to the document
          for (let imageData of images) {
            const aspectRatio = imageData.height / imageData.width;
            const scaledHeight = Math.round(MAX_WIDTH * aspectRatio);
            const imageBuffer = base64ToArrayBuffer(imageData.data);

            content.push(
              new Paragraph({
                children: [
                  new ImageRun({
                    data: imageBuffer,
                    transformation: {
                      width: MAX_WIDTH,
                      height: scaledHeight
                    }
                  })
                ]
              })
            );
          }
        } else if (fileType.includes('image')) {
          // Handle image files directly
          const response = await fetch(attachment);
          const arrayBuffer = await response.arrayBuffer();

          // Create temporary image to get dimensions
          const blob = new Blob([arrayBuffer]);
          const imageUrl = URL.createObjectURL(blob);

          const dimensions = await new Promise<{
            width: number;
            height: number;
          }>((resolve) => {
            const img = new Image();
            img.onload = () => {
              resolve({
                width: img.width,
                height: img.height
              });
              URL.revokeObjectURL(imageUrl);
            };
            img.src = imageUrl;
          });

          // Calculate height maintaining aspect ratio
          const aspectRatio = dimensions.height / dimensions.width;
          const scaledHeight = Math.round(MAX_WIDTH * aspectRatio);

          content.push(
            new Paragraph({
              children: [
                new ImageRun({
                  data: arrayBuffer,
                  transformation: {
                    width: MAX_WIDTH,
                    height: scaledHeight
                  }
                })
              ]
            })
          );
        } else {
          // For other file types, add as hyperlink
          content.push(
            new Paragraph({
              children: [
                new TextRun({ text: '', break: 1 }),
                new ExternalHyperlink({
                  children: [
                    new TextRun({
                      text: 'Source link',
                      style: 'Hyperlink'
                    })
                  ],
                  link: attachment
                })
              ]
            })
          );
        }
      } catch (error) {
        console.error('Error processing attachment:', error);
        // Fallback to hyperlink
        content.push(
          new Paragraph({
            children: [
              new TextRun({ text: '', break: 1 }),
              new ExternalHyperlink({
                children: [
                  new TextRun({
                    text: attachment,
                    style: 'Hyperlink'
                  })
                ],
                link: attachment
              })
            ]
          })
        );
      }
    }

    return content;
  };

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { size: '11pt', font: 'Calibri' },
          paragraph: { spacing: { after: 60, before: 60 } }
        }
      }
    },
    sections: [
      {
        children: await Promise.all(
          streams.map(async (stream) => [
            generateTableDetail(stream),
            ...(await generateAttachmentContent([stream.attachments[0]?.link])),
            new Paragraph({
              children: [
                new TextRun({
                  text: '',
                  break: 1
                })
              ]
            }),
            new Paragraph({
              text: stream.title,
              heading: HeadingLevel.HEADING_1
            }),
            ...generateParagraphs(stream.content),
            new Paragraph({
              children: [
                new TextRun({
                  text: '',
                  break: 2
                })
              ]
            })
            // generateAttachmentLink(stream.attachments)
          ])
        ).then((results) => results.flat())
      }
    ]
  });

  return doc;
}
