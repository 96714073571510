import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import { OFFLINE_MEDIA_CLIPPING } from 'src/route';
import { ArrowBack, Download, KeyboardArrowDown } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import useOfflineMedia from 'src/services/useOfflineMedia';
import useQueryUrl from 'src/hooks/useQuery';
import { useAppSelector } from 'src/app/hooks';
import { useMutation } from '@apollo/client';
import { CREATE_DOWNLOAD_CSV_JOB_FOR_OFFLINE_MEDIA } from 'src/services/graphql/offlineMedia/mutation';
import { IGranularity } from 'src/models/general';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useAlert } from 'src/hooks/useAlert';
import { generateOfmDoc } from 'src/utils/generateOfmDoc';
import { saveAs } from 'file-saver';
import { Packer } from 'docx';
import TableSelectGroupPortal from 'src/components/TableSelectGroupPortal';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { IGroupPortal } from 'src/models/groupPortal';
import useGroupPortal from 'src/services/useGroupPortal';
import JSZip from 'jszip';
import { IOfflineMedia } from 'src/models/offlineMedia';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderOfflineMediaClippingById = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleClickAlert } = useAlert();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const query = useQueryUrl();
  const portal = query.get('portal');
  const articleId = query.get('id');
  const projectId = useAppSelector((state) => state.users.project.id);
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );

  const { getGroupPortal } = useGroupPortal();

  const { languageSelected, activeArticle, articleList } = useAppSelector(
    (state) => state.storeOfflineMediaStream
  );

  const advSearchOfflineMedia = useAppSelector(
    (state) => state.advanceSearchOfflineMedia
  );

  const fillAdvanceSearchOfflineMedia = fillParamAdvanceSearchEmpty(
    advSearchOfflineMedia
  );

  const { groupPortalList, selectedGroupPortalId } = useAppSelector(
    (state) => state.groupPortal
  );
  const { filteredList, handleFilter } = useFiltersByName<IGroupPortal>(
    groupPortalList.data
  );

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const [openSelectGroup, setOpenSelectGroup] = useState(false);

  const { getClippingOfflineMediaById, resGetClippingOfflineMediaById } =
    useOfflineMedia();

  const [downloadOfflineMedia] = useMutation(
    CREATE_DOWNLOAD_CSV_JOB_FOR_OFFLINE_MEDIA
  );

  const finalPayload = {
    ...fillAdvanceSearchOfflineMedia,
    ...dateRangeFormatted,
    clippingId: id,
    includeKeywords: advSearchOfflineMedia.includeKeywords,
    languageList: languageSelected === 'all-language' ? [] : [languageSelected],
    projectId,
    granularity,
    timezone: 7
  };

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const openDownload = Boolean(anchorElDownload);
  const idPopup = openDownload ? 'download-popover' : undefined;

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingWord, setIsDownloadingWord] = useState(false);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleDownloadWord = async () => {
    setIsDownloadingWord(true);
    try {
      if (articleId) {
        // download only one article
        const doc = await generateOfmDoc([activeArticle]);
        Packer.toBlob(doc).then((blob) => {
          saveAs(blob, `${activeArticle.originalId}.docx`);
        });
      } else {
        // download all articles
        const doc = await generateOfmDoc(articleList.result);
        Packer.toBlob(doc).then((blob) => {
          saveAs(
            blob,
            `${
              resGetClippingOfflineMediaById.data.article_getClippingArticleById
                .name
            }-${new Date()
              .toLocaleDateString('id-ID', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              })
              .split(' ')
              .join('-')}.docx`
          );
        });
      }
      handleCloseDownload();
    } catch (error) {
      console.log(error);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'error',
        message: 'Failed to download report, please try again.'
      });
    } finally {
      setIsDownloadingWord(false);
    }
  };

  const handleDownloadCsv = () => {
    handleCloseDownload();
    downloadOfflineMedia({
      variables: {
        ...finalPayload,
        mediaList: !portal || portal === 'all-media' ? [] : [portal]
      },
      onCompleted: () =>
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'success',
          message:
            'It may take some time for the report to be processed.\n The report can be downloaded from the Activity List on the top right corner.'
        }),
      onError(error) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'error',
          message: 'Failed to download report, please try again.'
        });
        console.log(error.message);
      }
    });
  };

  const downloadAndZipAttachments = async (articles: IOfflineMedia[]) => {
    const zip = new JSZip();

    // Check if there are any articles with attachments
    const articlesWithAttachments = articles.filter(
      (article) => article.attachments && article.attachments.length > 0
    );

    if (articlesWithAttachments.length === 0) {
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'warning',
        message: 'No attachments found for the selected articles.'
      });
      return;
    }

    try {
      let successfulDownloads = 0;
      let failedDownloads = 0;

      // Show progress alert
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'info',
        message: 'Starting to download attachments. Please wait...'
      });

      for (const article of articlesWithAttachments) {
        if (article.attachments?.length > 0) {
          const validAttachments = article.attachments.filter(
            (attachment) => attachment.link
          );

          await Promise.all(
            validAttachments.map(async (attachment, index) => {
              try {
                const response = await fetch(attachment.link);

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }

                const fileData = await response.arrayBuffer();

                // Get file extension from URL
                const fileName = `${
                  attachment.link.split('/').pop() || ''
                }.${response.headers.get('Content-Type').split('/').pop()}`;

                // Add the file to the zip
                zip.file(
                  `${article.originalId}_${index + 1}_${fileName}`,
                  fileData
                );

                successfulDownloads++;
              } catch (error) {
                console.error(
                  `Failed to download attachment for article ${article.originalId}:`,
                  error
                );
                failedDownloads++;
              }
            })
          );
        }
      }

      if (successfulDownloads === 0) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'error',
          message: 'No attachments were successfully downloaded.'
        });
        return;
      }

      // Generate and download the zip file
      const zipContent = await zip.generateAsync({ type: 'blob' });

      // Create a meaningful filename
      const zipFileName = `${
        resGetClippingOfflineMediaById.data.article_getClippingArticleById.name
      }_attachments-${new Date()
        .toLocaleDateString('id-ID', {
          year: 'numeric',
          month: 'short',
          day: '2-digit'
        })
        .split(' ')
        .join('-')}.zip`;

      saveAs(zipContent, zipFileName);

      // Show final status
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'success',
        message: `Successfully downloaded ${successfulDownloads} attachment${
          successfulDownloads !== 1 ? 's' : ''
        }${
          failedDownloads > 0
            ? `. Failed to download ${failedDownloads} attachment${
                failedDownloads !== 1 ? 's' : ''
              }`
            : ''
        }`
      });
    } catch (error) {
      console.error('Error creating zip file:', error);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'error',
        message: 'Failed to create zip file. Please try again.'
      });
    }
  };

  const handleDownloadAttachments = async () => {
    setIsDownloading(true);
    try {
      if (articleId) {
        await downloadAndZipAttachments([activeArticle]);
      } else {
        await downloadAndZipAttachments(articleList.result);
      }
      handleCloseDownload();
    } catch (error) {
      console.error('Error in handleDownloadAttachments:', error);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'error',
        message: 'Failed to download attachments. Please try again.'
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    getGroupPortal(projectId);
  }, [projectId]);

  useEffect(() => {
    getClippingOfflineMediaById({
      variables: {
        clippingId: id
      }
    });
  }, [id]);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack direction="row" alignItems="center">
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`,
                  mr: theme.spacing(2)
                }}
                size="small"
                onClick={() => navigate(OFFLINE_MEDIA_CLIPPING)}
              >
                <ArrowBack />
              </Fab>
              {!resGetClippingOfflineMediaById.loading && (
                <Typography variant="h1">
                  {
                    resGetClippingOfflineMediaById.data
                      ?.article_getClippingArticleById.name
                  }
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <FormControl size="small" sx={{ flexBasis: '370px', mb: 2 }}>
              <Select
                name="group"
                open={openSelectGroup}
                onClose={() => setOpenSelectGroup(false)}
                onOpen={() => setOpenSelectGroup(true)}
                value={selectedGroupPortalId}
                defaultValue={selectedGroupPortalId}
              >
                <MenuItem
                  value={'_default'}
                  key={'_default'}
                  sx={{ display: 'none' }}
                >
                  Group
                </MenuItem>
                {groupPortalList.data.map((group) => (
                  <MenuItem
                    key={group.id}
                    value={group.id}
                    sx={{ display: 'none' }}
                  >
                    {group.displayName}
                  </MenuItem>
                ))}

                <Box sx={{ p: theme.spacing(2) }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Group</Typography>
                    {/* <Button
                    size="small"
                    variant="outlined"
                    onClick={(event) => {
                      handleClickOpenAddGroup();
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    New group +
                  </Button> */}
                  </Stack>
                  {/* <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  fullWidth
                  placeholder="Search Source"
                  onChange={(event) => {
                    handleFilter(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                /> */}
                  <Box sx={{ mt: theme.spacing(1) }}>
                    <TableSelectGroupPortal
                      groupPortals={[
                        {
                          id: '_default',
                          name: 'All',
                          displayName: 'All',
                          portals: [],
                          isActive: false,
                          createdAt: '-'
                        },
                        ...filteredList
                      ]}
                      selectedGroupById={selectedGroupPortalId}
                      loading={groupPortalList.loading}
                      onClick={() => setOpenSelectGroup(false)}
                    />
                  </Box>
                </Box>
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<Download />}
              endIcon={<KeyboardArrowDown />}
              sx={{
                minWidth: theme.spacing(6),
                height: 36,
                backgroundColor: '#FBFBFB',
                borderRadius: theme.spacing(1)
              }}
              onClick={handleClickDownload}
            >
              Download
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        id={idPopup}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItemButton
            disabled={isDownloadingWord}
            onClick={() => handleDownloadWord()}
          >
            <ListItemText
              primary={
                isDownloadingWord ? 'Preparing Download...' : 'Word - Articles'
              }
            />
          </ListItemButton>
          <ListItemButton onClick={() => handleDownloadCsv()}>
            <ListItemText primary="CSV - All Metrics" />
          </ListItemButton>
          <ListItemButton
            onClick={() => handleDownloadAttachments()}
            disabled={isDownloading}
          >
            <ListItemText
              primary={
                isDownloading ? 'Preparing Download...' : 'All Attachments'
              }
            />
          </ListItemButton>
        </List>
      </Popover>
    </HeaderWrapper>
  );
};

export default HeaderOfflineMediaClippingById;
